const nlBE= {
    login: {
      title: 'Aanmelden',
      sign_in_btn: 'Aanmelden'
    },
    bar: {
        logout: 'Afmelden',
        languages: 'Talen',
        settings: 'Instellingen openen'
    },
    language: {
      name: 'Nederlands (België)',
    },
    attributePage: {
        title: 'Attributen wijzigingen',
        viewChanges: 'Wijzigingen bekijken',
        dateTime: 'Datum',
        notificationType: 'Soort melding',
        notificationValue: 'Meldingswaarde',
        oldValue: 'Oude waarde',
        newValue: 'Nieuwe waarde',
        accept: 'Aanvaarden',
        reject: 'Weigeren',
    },
    Home: 'Startpagina',
    load: 'Voor de eerste keer laden ...',
    Requested: "Verzocht",
    Viewed: "Bekeken",
    Executing: "Aan het uitvoeren",
    Completed: "Voltooid",
    Expired: "Verlopen",
    Cancelled: "Geannuleerd",
    Error: "Fout",
    Rejected: "Geweigerd",
    error: 'Er is iets fout gelopen; probeer het later opnieuw.',
    displayname: 'Schermnaam',
    givenname: 'Naam',
    surname: 'Achternaam',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Naam',
    firstname: 'Naam',
    lastname: 'Achternaam',
    footer_copyright: 'Alle rechten voorbehouden.'
  };
  
  export default nlBE;
  
const jaJP= {
    login: {
      title: 'サインイン',
      sign_in_btn: 'サインイン'
    },
    bar: {
        logout: 'ログアウト',
        languages: '言語',
        settings: '設定を開く'
    },
    language: {
      name: '英語（米国）',
    },
    attributePage: {
        title: '属性の変更',
        viewChanges: '変更の表示',
        dateTime: '日付',
        notificationType: '通知のタイプ',
        notificationValue: '通知の値',
        oldValue: '古い値',
        newValue: '新しい値',
        accept: '承諾',
        reject: '却下',
    },
    Home: 'ホーム',
    load: '初期読み込みを実行しています･･･',
    Requested: "リクエスト済み",
    Viewed: "閲覧済み",
    Executing: "実行中",
    Completed: "完了",
    Expired: "有効期限切れ",
    Cancelled: "キャンセル済み",
    Error: "エラー",
    Rejected: "却下済み",
    error: 'エラーが発生しました。後でもう一度やり直してください。',
    displayname: '表示名',
    givenname: '名',
    surname: '姓',
    email: 'Eメール',
    mail: 'Eメール',
    name: '名',
    firstname: '名',
    lastname: '姓',
    footer_copyright: '無断複写・転載を禁ず。'
  };
  
  export default jaJP;
  
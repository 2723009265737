const deAT= {
    login: {
      title: 'Anmelden',
      sign_in_btn: 'Anmelden'
    },
    bar: {
        logout: 'Abmelden',
        languages: 'Sprachen',
        settings: 'Einstellungen öffnen'
    },
    language: {
      name: 'Deutsch (DE)',
    },
    attributePage: {
        title: 'Änderungen der Eigenschaften',
        viewChanges: 'Siehe Änderungen',
        dateTime: 'Datum',
        notificationType: 'Art der Benachrichtigung',
        notificationValue: 'Wert der Benachrichtigung',
        oldValue: 'Alter Wert',
        newValue: 'Neuer Wert',
        accept: 'Akzeptieren',
        reject: 'Ablehnen',
    },
    Home: 'Startseite',
    load: 'Durchführen des ersten Ladevorgangs ...',
    Requested: "Angefordert",
    Viewed: "Gesehen",
    Executing: "Ausführen von",
    Completed: "Erledigt",
    Expired: "Abgelaufen",
    Cancelled: "Abgebrochen",
    Error: "Fehler",
    Rejected: "Abgelehnt",
    error: 'Es ist ein Fehler aufgetreten; bitte versuchen Sie es später noch einmal.',
    displayname: 'Anzeigename',
    givenname: 'Name',
    surname: 'Nachname',
    email: 'E-Mail',
    mail: 'E-Mail',
    name: 'Name',
    firstname: 'Name',
    lastname: 'Nachname',
    footer_copyright: 'Alle Rechte vorbehalten.'
  };
  
  export default deAT;
  
const frBE= {
    login: {
      title: 'Se connecter',
      sign_in_btn: 'Se connecter'
    },
    bar: {
        logout: 'Se déconnecter',
        languages: 'Langues',
        settings: 'Ouvrir les paramètres'
    },
    language: {
      name: 'Français (BE)',
    },
    attributePage: {
        title: 'Modifications d’attributs',
        viewChanges: 'Afficher les modifications',
        dateTime: 'Date',
        notificationType: 'Type de notification',
        notificationValue: 'Valeur de notification',
        oldValue: 'Ancienne valeur',
        newValue: 'Nouvelle valeur',
        accept: 'Accepter',
        reject: 'Rejeter',
    },
    Home: 'Accueil',
    load: 'Chargement initial en cours...',
    Requested: "Demandé",
    Viewed: "Affiché",
    Executing: "En cours d’exécution",
    Completed: "Complété",
    Expired: "Expiré",
    Cancelled: "Annulé",
    Error: "Erreur",
    Rejected: "Rejeté",
    error: 'Un problème est survenu. Veuillez réessayer plus tard.',
    displayname: 'Nom affiché',
    givenname: 'Prénom',
    surname: 'Nom',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Prénom',
    firstname: 'Prénom',
    lastname: 'Nom',
    footer_copyright: 'Tous droits réservés.'
  };
  
  export default frBE;
  
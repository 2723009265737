const esAR= {
    login: {
      title: 'Iniciar sesión',
      sign_in_btn: 'Iniciar sesión'
    },
    bar: {
        logout: 'Cerrar sesión',
        languages: 'Idiomas',
        settings: 'Abrir configuración'
    },
    language: {
      name: 'Inglés (EE. UU.)',
    },
    attributePage: {
        title: 'Cambios de atributos',
        viewChanges: 'Ver cambios',
        dateTime: 'Fecha',
        notificationType: 'Tipo de notificación',
        notificationValue: 'Valor de la notificación',
        oldValue: 'Valor anterior',
        newValue: 'Valor nuevo',
        accept: 'Aceptar',
        reject: 'Rechazar',
    },
    Home: 'Inicio',
    load: 'Realizando la carga inicial…',
    Requested: "Solicitado",
    Viewed: "Visto",
    Executing: "Ejecutando",
    Completed: "Completado",
    Expired: "Caducado",
    Cancelled: "Cancelado",
    Error: "Error",
    Rejected: "Rechazado",
    error: 'Algo salió mal; intente nuevamente más tarde.',
    displayname: 'Nombre para mostrar',
    givenname: 'Nombre',
    surname: 'Apellido',
    email: 'Correo electrónico',
    mail: 'Correo electrónico',
    name: 'Nombre',
    firstname: 'Nombre',
    lastname: 'Apellido',
    footer_copyright: 'Todos los derechos reservados.'
  };
  
  export default esAR;
  
const arIL= {
    login: {
      title: 'تسجيل الدخول',
      sign_in_btn: 'تسجيل الدخول'
    },
    bar: {
        logout: 'تسجيل الخروج',
        languages: 'اللغات',
        settings: 'فتح الإعدادات'
    },
    language: {
      name: 'الإنجليزية (المملكة المتحدة)',
    },
    attributePage: {
        title: 'تغيرات السمات',
        viewChanges: 'شاهد التغيرات',
        dateTime: 'التاريخ',
        notificationType: 'نوع التنبيه',
        notificationValue: 'قيمة التنبيه',
        oldValue: 'قيمة قديمة',
        newValue: 'قيمة جديدة',
        accept: 'قبول',
        reject: 'رفض',
    },
    Home: 'الرئيسية',
    load: 'جاري تنفيذ التحميل التمهيدي',
    Requested: "مطلوب",
    Viewed: "مُشاهد",
    Executing: "يجري التنفيذ",
    Completed: "مكتمل",
    Expired: "منتهي الصلاحية",
    Cancelled: "مُلغى",
    Error: "خطأ",
    Rejected: "مرفوض",
    error: 'حدث خطأ ما؛ الرجاء المحاولة لاحقًا.',
    displayname: 'الاسم على شاشة العرض',
    givenname: 'الاسم الأول',
    surname: 'اسم العائلة',
    email: 'البريد الإلكتروني',
    mail: 'البريد الإلكتروني',
    name: 'الاسم الأول',
    firstname: 'الاسم الأول',
    lastname: 'اسم العائلة',
    footer_copyright: 'جميع الحقوق محفوظة.'
  };
  
  export default arIL;
  
import "./Bar.css";
import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import { PageNames } from "../../App";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useAccount, useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { useLocales } from "../../locales";
import { useState } from "react";
import MenuPopover from "../menu-popover";
import { Stack, MenuItem, IconButton } from "@mui/material";
import Image from "../../components/image";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  "@media all": {
    minHeight: 100,
  },
}));

function stringAvatar(name: string | undefined) {
  if (!name || name.trim() === "") {
    return "";
  }

  const firstName = name.split(" ")[0];
  const lastName = name.split(" ")[1];

  if (!firstName || firstName.trim() === "") {
    return "";
  }

  if (!lastName || lastName.trim() === "") {
    return {
      children: `${firstName[0]}`,
    };
  }

  return {
    children: `${firstName[0]}${lastName[0]}`,
  };
}

export default function Bar() {
  let msal = useMsal();
  let activeAccount = msal.instance.getActiveAccount();
  let location = useLocation();
  const [pageName, setPageName] = React.useState<string | undefined>("Home");
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  
  const { translate, allLangs, currentLang, onChangeLang } = useLocales();

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  const logout = () => {
    const logoutRequest = {
      account: activeAccount,
    };
    msal.instance.logoutRedirect(logoutRequest);
  };

  React.useEffect(() => {
    const pageName = PageNames.get(location.pathname);
    document.title = `ICON plc | ${translate(pageName)}`;
    setPageName(pageName);
  }, [location, translate]);

  const isAuthenticated = useIsAuthenticated();
  const user = useAccount();

  return (
    <AppBar position="absolute">
      {/* <Container maxWidth=""> */}
      <StyledToolbar>
        {/* edge="start" disableRipple={true} */}
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
          }}
        >
          <img src="/logo.svg" className="logo-bar" alt="ICON logo" />
        </Typography>

        {/* Mobile View */}
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {Array.from(PageNames)
              .filter((p) => p[0] !== "/")
              .map((page) => {
                let pageName = page[1];
                let path = page[0];
                return (
                  <MenuItem
                    key={pageName}
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={path}
                    selected={path === location.pathname}
                  >
                    <Typography textAlign="center">{`${translate(
                      pageName
                    )}`}</Typography>
                  </MenuItem>
                );
              })}
          </Menu>
        </Box>

        <Typography
          variant="h5"
          noWrap
          component="a"
          href=""
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
          }}
        >
          <img src="/logo.svg" className="logo-bar" alt="ICON logo" />
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {Array.from(PageNames)
            .filter((p) => p[0] !== "/")
            .map((page) => {
              let pageName = page[1];
              let path = page[0];
              return (
                <MenuItem
                  key={pageName}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                  component={Link}
                  to={path}
                  selected={path === location.pathname}
                >
                  {`${translate(pageName)}`}
                </MenuItem>
              );
            })}
        </Box>
        {/* Profile */}
        {isAuthenticated && user ? (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={`${translate("bar.settings")}`}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} {...stringAvatar(user.name)} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="Logout" onClick={logout}>
                <Typography textAlign="center">
                {`${translate("bar.logout")}`}
                </Typography>
              </MenuItem>
              <MenuItem key="Logout" onClick={handleOpenPopover}>
                <Typography textAlign="center">
                {`${translate("bar.languages")}`}
                </Typography>
                
              </MenuItem>
              <MenuPopover
  open={openPopover}
  onClose={handleClosePopover}
  sx={{ width: 180 }}
>
  <Stack
    spacing={0.0} // Reduce the space between items
    justifyContent={"left"}
    sx={{
      maxHeight: {
        xs: 'calc(100vh - 100px)', // For small screens
        sm: 'calc(100vh - 150px)', // For medium screens
        md: 'calc(100vh - 200px)', // For large screens
        lg: 'calc(100vh - 250px)', // For extra large screens
      },
      overflowY: 'auto',
    }}
  >
    {allLangs.filter(lang => lang.label !== "").map((option) => (
      <MenuItem
        key={option.value}
        selected={option.value === currentLang.value}
        onClick={() => handleChangeLang(option.value)}
        sx={{ padding: '4px 8px', margin: 0, minHeight: '24px', lineHeight: '1.2' }} // Reduce padding, margin, minHeight, and lineHeight
      >
        {option.label}
      </MenuItem>
    ))}
  </Stack>
</MenuPopover>
            </Menu>
          </Box>
          
        ) : (
          ""
        )}
      </StyledToolbar>
      {/* </Container> */}
    </AppBar>
  );
}

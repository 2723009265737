const plPL= {
    login: {
      title: 'Logowanie',
      sign_in_btn: 'Logowanie'
    },
    bar: {
        logout: 'Wylogowanie',
        languages: 'Języki',
        settings: 'Otwórz ustawienia'
    },
    language: {
      name: 'Polski',
    },
    attributePage: {
        title: 'Zmiany atrybutów',
        viewChanges: 'Podgląd zmian',
        dateTime: 'Data',
        notificationType: 'Typ powiadomienia',
        notificationValue: 'Wartość powiadomienia',
        oldValue: 'Stara wartość',
        newValue: 'Nowa wartość',
        accept: 'Akceptuj',
        reject: 'Odrzuć',
    },
    Home: 'Strona główna',
    load: 'Wstępne ładowanie...',
    Requested: "Żądane",
    Viewed: "Przeglądane",
    Executing: "Wykonywane",
    Completed: "Zakończone",
    Expired: "Wygasłe",
    Cancelled: "Anulowane",
    Error: "Błąd",
    Rejected: "Odrzucone",
    error: 'Coś poszło nie tak. Proszę spróbować ponownie później.',
    displayname: 'Wyświetlana nazwa / imię i nazwisko',
    givenname: 'Imię',
    surname: 'Nazwisko',
    email: 'E-mail',
    mail: 'E-mail',
    name: 'Imię',
    firstname: 'Imię',
    lastname: 'Nazwisko',
    footer_copyright: 'Wszelkie prawa zastrzeżone.'
  };
  
  export default plPL;
  